import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

class ChangeProductForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionID: this.props.subscriptionID
        }
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Text className="text-muted">
                            <b>Click here to Upgrade or Downgrade your subscription.</b>
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" className="muffin-button" href={'/change-sub/' + this.state.subscriptionID}>
                        Change Product
                    </Button>
                </Form>
            </div>
        )
    }
}

export default ChangeProductForm;