import React, { Component } from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import CookieConsent from "react-cookie-consent";
import { Button, Card, Spinner, Container } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import ChangeDomainNameForm from './ManageDomainComponents/ChangeDomainNameForm';
import ChangecPanelUsernameForm from './ManageDomainComponents/ChangecPanelUsernameForm';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import DeletecPanelForm from './ManageDomainComponents/DeletecPanelForm';
import CreateCPanelAccountForm from './ManageDomainComponents/CreateCPanelAccountForm';
import ManageSubscriptionInformationForm from './ManageDomainComponents/ManageSubscriptionInformationForm';
import ClaimFreeDomainForm from './ManageDomainComponents/ClaimFreeDomainForm';
import ChangeFreeDomainNameForm from './ManageDomainComponents/ChangeFreeDomainNameForm';
import { HOSTING_DOMAIN } from './Variables/APIEndPoints/endpoints';
import ChangeProductForm from './ManageDomainComponents/ChangeProductForm';
import ChangecPanelPasswordForm from './ManageDomainComponents/ChangecPanelPasswordForm';
import HelpfulVideos from './ManageDomainComponents/HelpfulVideos';


class ManageDomain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "",
            stripeSubscription: "",
            txt_record : "",
            loggedOut: false,
            loadingManageHostingData: false,
            username: "",
            management_info: {},
            alreadyHasAccount: false,
            free_domain_included : false,
            emailRelayIP: "209.141.59.43",
            overviewMenuSelected: true
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount(){
        // Getting the search parameters.
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        this.setState({stripeSubscription : params.get("stripeSubscription")});
        
        // Getting the subscription id for strip to be used in the API Post Method.
        let stripeSubscription = params.get("stripeSubscription");
        let requestOptions = {};
        this.setState({loadingManageHostingData : true});
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({stripeSubscription : stripeSubscription})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({stripeSubscription : stripeSubscription})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/get-domain-management-information', requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({management_info : data.management_information});
            this.setState({loadingManageHostingData : false});
        }
        else{
            this.setState({loggedOut : true});
            this.setState({loadingManageHostingData : false});
        }
    }

    handleChange() {
        this.setState({overviewMenuSelected : !this.state.overviewMenuSelected});
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container>
                    <div className="">
                        {/*
                        * If the user is logged out.
                        */}
                        {this.state.loggedOut && 
                        <>
                        <h1>You've been logged out</h1>
                        <Button className="rounded muffin-button" href='/account' size="lg"><b>Click to Return</b></Button>
                        </>
                        }

                        {/*
                        * If the user is managing an already existing domain.
                        */}
                        {this.state.loadingManageHostingData &&
                            <Spinner/>
                        }
                        {!this.state.loggedOut && !this.state.loadingManageHostingData && this.state.management_info.purchases && 
                         this.state.management_info?.purchases.length !== 0 && this.state.management_info.purchases[0].domains && this.state.management_info.purchases[0].domains.length !== 0 && 
                        <>
                            <h1>{this.state.management_info.purchases[0].domain}</h1>
                            <div className="menu-items">
                                <div className={this.state.overviewMenuSelected ? "menu-item-selected" : "menu-item"} onClick={this.handleChange}>
                                    <h1 className="center">Overview
                                    </h1>
                                </div>

                                <div className={this.state.overviewMenuSelected ? "menu-item" : "menu-item-selected"} onClick={this.handleChange}>
                                    <h1 className="center">Helpful Videos
                                    </h1>
                                </div>
                            </div>
                            
                            <br></br>
                            {this.state.overviewMenuSelected? 
                            <>
                            <Card>
                                <Card.Header className="mainTopicsBoxes"><b>Domain Information</b></Card.Header>
                                <Card.Body>
                                    <Card>
                                        <Card.Body>
                                            <b>cPanel Username:</b> {this.state.management_info.purchases[0].domains[0].username}
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <b>Server IP: </b> 34.23.61.184
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <b>Email Relay IP: </b> {this.state.emailRelayIP}
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <b>Domain Verification TXT Record:</b> {this.state.management_info.purchases[0].domain_validation_txt_record}
                                        </Card.Body>
                                    </Card>
                                </Card.Body>
                            </Card>
                            <br></br>
                            <Card>
                                <Card.Header className="mainTopicsBoxes">
                                    <b>Actions</b> (These Processes can take up to 2 minutes.)
                                </Card.Header>
                                <Card.Body>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Login to cPanel</b></Card.Header>
                                            <Card.Body>
                                                <Button href="https://muffinmanhosting.com/cpanel" className="muffin-button">To cPanel</Button>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Change cPanel Username</b></Card.Header>
                                            <Card.Body>
                                                <ChangecPanelUsernameForm stripe_subscription_id={this.state.stripeSubscription} domain={this.state.management_info.purchases[0].domain}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="header-boxes"><b>Change cPanel Password</b></Card.Header>
                                            <Card.Body>
                                                <ChangecPanelPasswordForm subscriptionID={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Change Domain</b></Card.Header>
                                            <Card.Body>
                                                <ChangeDomainNameForm stripe_subscription_id={this.state.stripeSubscription} domain={this.state.management_info.purchases[0].domain}/>
                                            </Card.Body>
                                        </Card>
                                        <>
                                            <br></br>
                                            <Card>
                                                <Card.Header className="headerBoxes"><b>Change Domain: Free Domain</b></Card.Header>
                                                <Card.Body>
                                                    <ChangeFreeDomainNameForm stripe_subscription_id={this.state.stripeSubscription} domain={this.state.management_info.purchases[0].domain}/>
                                                </Card.Body>
                                            </Card>
                                        </>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Delete cPanel Account</b></Card.Header>
                                            <Card.Body>
                                                <DeletecPanelForm stripe_subscription_id={this.state.stripeSubscription} domain={this.state.management_info.purchases[0].domain}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Manage Individual Subscription</b></Card.Header>
                                            <Card.Body>
                                                <ManageSubscriptionInformationForm stripeSubscription={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Change Product</b></Card.Header>
                                            <Card.Body>
                                                <ChangeProductForm subscriptionID={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                    </Card.Body>   
                            </Card>
                            <br></br>
                            </>
                            :
                                <>
                                    <HelpfulVideos alreadyHosting={true}/>
                                </>
                            }
                        </>
                        }
                        {!this.state.loggedOut && !this.state.loadingManageHostingData && this.state.management_info.purchases && 
                         this.state.management_info?.purchases.length !== 0 && this.state.management_info.purchases[0].domains && this.state.management_info.purchases[0].domains.length === 0 && this.state.management_info.purchases[0].typeOfProduct
                         && this.state.management_info.purchases[0].typeOfProduct.is_hosting_plan === true && 
                        <>
                            <>
                            <h1>Get Started Hosting!</h1>
                            <div className="menu-items">
                                <div className={this.state.overviewMenuSelected ? "menu-item-selected" : "menu-item"} onClick={this.handleChange}>
                                    <h1 className="center">Overview
                                    </h1>
                                </div>

                                <div className={this.state.overviewMenuSelected ? "menu-item" : "menu-item-selected"} onClick={this.handleChange}>
                                    <h1 className="center">Helpful Videos
                                    </h1>
                                </div>
                            </div>
                            <br></br>
                            {this.state.overviewMenuSelected? 
                                <>
                                    <>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Create cPanel Account</b></Card.Header>
                                            <Card.Body>
                                                <CreateCPanelAccountForm stripeSubscription={this.state.stripeSubscription} txt_record={this.state.management_info.purchases[0].domain_validation_txt_record}/>
                                            </Card.Body>
                                        </Card>
                                    </>
                                    
                                    <br></br>
                                    <>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Create cPanel Account: Free Domain</b></Card.Header>
                                            <Card.Body>
                                                <ClaimFreeDomainForm stripeSubscription={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                    </>
                                    
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Manage Individual Subscription</b></Card.Header>
                                            <Card.Body>
                                                <ManageSubscriptionInformationForm stripeSubscription={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                        <Card>
                                            <Card.Header className="headerBoxes"><b>Change Product</b></Card.Header>
                                            <Card.Body>
                                                <ChangeProductForm subscriptionID={this.state.stripeSubscription}/>
                                            </Card.Body>
                                        </Card>
                                        <br></br>
                                    </>
                                
                                :
                                <>
                                    <HelpfulVideos alreadyHosting={false}/>
                                </>
                            }
                            </>
                        </>
                        }
                        {!this.state.loggedOut && !this.state.loadingManageHostingData && this.state.management_info.purchases && 
                         this.state.management_info?.purchases.length !== 0 && this.state.management_info.purchases[0].domains && this.state.management_info.purchases[0].domains.length === 0 && this.state.management_info.purchases[0].typeOfProduct
                         && this.state.management_info.purchases[0].typeOfProduct.isAcademyPlan_code === true && this.state.management_info.purchases[0].typeOfProduct.is_hosting_plan === false && 
                         <>
                            <h1>Academy Subscription</h1>
                            <Card>
                                <Card.Header className="headerBoxes"><b>Manage Individual Subscription</b></Card.Header>
                                <Card.Body>
                                    <ManageSubscriptionInformationForm stripeSubscription={this.state.stripeSubscription}/>
                                </Card.Body>
                            </Card>
                            <br></br>
                            <Card>
                                <Card.Header className="headerBoxes"><b>Change Product</b></Card.Header>
                                <Card.Body>
                                    <ChangeProductForm subscriptionID={this.state.stripeSubscription}/>
                                </Card.Body>
                            </Card>
                            <br></br>
                         </>
                        }
                        {!this.state.loggedOut &&
                            <Button href='/account' className="muffin-button"><BsFillArrowLeftCircleFill/> Back To Account</Button>
                        }
                        <br></br>
                        <br></br>
                    </div>
                </Container>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default ManageDomain;