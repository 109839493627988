import React, { Component } from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import { Button, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

class SubscriptionDeleted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    render() {
        return(
            <div>
                <NavBar />
                <Container>
                    <div className="float-left">
                        <h1>WE'RE SAD TO SEE YOU GO!</h1>
                        <p>You still have access to your subscription until the end of your pay period.</p>
                        <Link to="/account"><Button>Back To Account</Button></Link>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default SubscriptionDeleted;
