import React, { Component } from 'react';
import NavBar from './NavBar';
import { GoogleLogin } from '@react-oauth/google';
import AccountInfo from './AccountInfo';
import Cookies from 'js-cookie';
import CookieConsent, {resetCookieConsentValue, getCookieConsentValue} from "react-cookie-consent";
import { Modal, Button, Container} from 'react-bootstrap';
import { GOOGLE_AUTH_DOMAIN } from './Variables/APIEndPoints/endpoints';
import logo from '../images/icon.png';
import Footer from './Footer';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            email: "",
            name: "",
            picture: "",
            studentEmailVerified: false,
            termsAgreed: false,
            loading: true,
            errorModal: false,
            error: ""
        }
        this.closeErrorModal = this.closeErrorModal.bind(this);
    }

    async componentDidMount() {
        // Logging the user in if they have cookies enabled or session is on going.
        if(getCookieConsentValue() === "true"){
            if(Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
                };
        
                const response = await fetch(GOOGLE_AUTH_DOMAIN, requestOptions);
                const data = await response.json();
                if (data.Result) {
                    this.setState({loggedIn : true});
                    this.setState({email: data.Result.email});
                    this.setState({name: data.Result.name});
                    this.setState({picture: data.Result.picture});
                    this.setState({loading: false});
                    if(data.isStudent === true){
                        this.setState({studentEmailVerified : true});
                    }
                    if(data.termsAgreed === true){
                        this.setState({termsAgreed : true});
                    }
                    sessionStorage.setItem('jwtToken', Cookies.get('jwtToken'));
                }else if(data.Error){
                    this.setState({loggedIn : false});
                    this.setState({loading: false});
                }   
            }
            else {
                this.setState({loggedIn : false});
                this.setState({loading: false});
            }
        }
        // cookie consent was false.
        else{
            if(sessionStorage.getItem('jwtToken') !== undefined && sessionStorage.getItem('jwtToken') !== ""){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
                };
        
                const response = await fetch(GOOGLE_AUTH_DOMAIN, requestOptions);
                const data = await response.json();
                if (data.Result) {
                    this.setState({loggedIn : true});
                    this.setState({email: data.Result.email});
                    this.setState({name: data.Result.name});
                    this.setState({picture: data.Result.picture});
                    this.setState({loading: false});
                    console.log(data.isStudent);
                    if(data.isStudent === true){
                        this.setState({studentEmailVerified : true});
                    }
                    if(data.termsAgreed === true){
                        this.setState({termsAgreed : true});
                    }
                }else if(data.Error){
                    this.setState({loggedIn : false});
                    this.setState({loading: false});
                }
            }
            else{
                this.setState({loggedIn : false});
                this.setState({loading: false});
            }
        }
    }

    async authorizeUser(credentialResponse) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + credentialResponse.credential},
            body: JSON.stringify({credentials : credentialResponse})
        };
        const response = await fetch(GOOGLE_AUTH_DOMAIN, requestOptions);
        const data = await response.json();
        if (data.Result){
            if(getCookieConsentValue() === "true"){
                Cookies.set('jwtToken', credentialResponse.credential, { expires: 7 });
                sessionStorage.setItem('jwtToken', credentialResponse.credential);
            }
            else{
                sessionStorage.setItem('jwtToken', credentialResponse.credential);
            }
            this.setState({loggedIn : true});
            this.setState({email: data.Result.email});
            this.setState({name: data.Result.name});
            this.setState({picture: data.Result.picture});
            this.setState({loading: false});
            if(data.isStudent === true){
                this.setState({studentEmailVerified : true});
            }
            if(data.termsAgreed === true){
                this.setState({termsAgreed : true});
            }
        }else if(data.Error){
            this.setState({loggedIn : false});
            this.setState({loading: false});
        }
        
    }

    resetCookieConsent() {
        resetCookieConsentValue();
    }

    closeErrorModal(){
        this.setState({errorModal : false});
    }

    render() {
        return (
            <div>
                <NavBar />
                {this.state.loggedIn === false && this.state.loading === false  && <div className="break-div">
                </div>}
                <div className="">
                {/* {this.state.loggedIn === false && this.state.loading === false  && <div>
                    <h3 className="title">You're Not Signed In!</h3>
                </div>} */}
                {this.state.loggedIn === false && this.state.loading === false && 
                        <div className="center loginScreenForm">
                            <div>
                                <div className="logo-container">
                                    <img src={logo} className="login-screen-logo"/>
                                    <hr></hr>
                                </div>
                                <br></br>
                                <h3><b>&#x1F604; Let's Get Started</b> &#x1F389;</h3>
                                <p>Login / Signup today and get started immediatley</p>
                                {/* <hr></hr> */}
                                <div className="break-div"></div>
                                <div className="googleLoginButton">
                                    <GoogleLogin
                                    theme="filled_blue"
                                    onSuccess={credentialResponse => {
                                        this.authorizeUser(credentialResponse);
                                    }}
                                    
                                    shape="circle"
                                    size="large"
                                    width="300px"
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                }
                {this.state.loggedIn && this.state.loading === false  && 
                    <AccountInfo email={this.state.email} name={this.state.name} picture={this.state.picture} studentEmailVerified={this.state.studentEmailVerified} termsAgreed={this.state.termsAgreed}/>
                }
                <Modal show={this.state.errorModal} onHide={this.closeErrorModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="error">{this.state.error}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeErrorModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                </div>
                {/* <Footer /> */}
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default Account;