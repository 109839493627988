import React, { Component } from 'react';
import NavBar from './NavBar';
import CookieConsent from "react-cookie-consent";
import {Container, Form, Spinner } from 'react-bootstrap';
import Footer from './Footer';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import './componentCSS/resources.css'

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            directory: undefined,
            markdown: "# Welcome to the documentation \nPlease click &#128070; a link to the left to find out more infromation on all topics!",
            loading: false,
            search_directory: []
        }
        this.createSearchDirectory = this.createSearchDirectory.bind(this);
    }

    async componentDidMount() {
        this.setState({loading : true});
        let response_directory = await fetch('/docs/resource_directory.json');
        let directory = await response_directory.json();
        this.setState({directory : directory.Directory});

        var urlParams = new URLSearchParams(window.location.search);
        var document_path = urlParams.get('path');
        if(document_path !== null){
            if(document_path.includes("https:") || document_path.includes("http:")){
                alert("Path contains illegal arguments.");
            }
            else{
                let response_document = await fetch(document_path);
                let document = await response_document.text();
                this.setState({markdown : document});
            }
        }
        this.setState({loading : false});
    }

    async updateDocument(url) {
        if(url.includes("https:") || url.includes("http:")){
            alert("Path contains illegal arguments.");
        }
        else{
            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set("path", url);
            window.location.search = queryParams.toString();
        }   
    }

    search(e) {
        this.setState({search : e.target.value}, () => {
            this.createSearchDirectory();
        });
    }

    createSearchDirectory() {
        let search_includes_directory = [];
        for(let i = 0; i < this.state.directory.length; i++){
            for(let x = 0; x < this.state.directory[i].Resources.length; x++){
                if(this.state.directory[i].Resources[x].Title.toLowerCase().includes(this.state.search.toLocaleLowerCase())){
                    let has = this.checkExistsInSearchDirectory(search_includes_directory, this.state.directory[i].Title);
                    if(has === false){
                        search_includes_directory.push({"Title" : this.state.directory[i].Title, "Resources" : [this.state.directory[i].Resources[x]]});
                    }
                    else{
                        let index = this.getIndexOfTitle(search_includes_directory, this.state.directory[i].Title);
                        if(index !== -1){
                            search_includes_directory[index].Resources.push(this.state.directory[i].Resources[x]);
                        }
                    }
                    
                }
            }
        }
        this.setState({search_directory : search_includes_directory});
    }

    checkExistsInSearchDirectory(search_includes_directory, title){
        for(let i = 0; i < search_includes_directory.length; i++){
            if(search_includes_directory[i].Title === title){
                return true;
            }
        }
        return false;
    }

    getIndexOfTitle(search_includes_directory,  title) {
        for(let i = 0; i < search_includes_directory.length; i++){
            if(search_includes_directory[i].Title === title){
                return i;
            }
        }
        return -1;
    }

    render() {
        return (
            <div>
                <NavBar />
                <div className="container-body">
                <Container>
                    {/* <h1>Resources</h1> */}
                    <div className="grid-container">
                        <div className="documentation-sidebar">
                            <div className="sidebar-items-container">
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3"><b>Search</b></Form.Label>
                                        <Form.Control type="text" value={this.state.search} className="user-info-control" onChange={e => this.search(e)}/>
                                    </Form.Group>
                                </Form>
                                {this.state.directory === undefined ? 
                                <Spinner/> : 
                                    <div>
                                        {this.state.search === "" ?
                                            <>
                                                {this.state.directory.map((heading, index) => (
                                                    <div key={index}>
                                                        <h5>{heading.Title}</h5>
                                                        <hr></hr>
                                                        <ul>
                                                            {heading.Resources.map((resource, index) => (
                                                                <div key={index} className="resource-container">
                                                                    <li>
                                                                        <h6 className="resource-title" onClick={() => this.updateDocument(resource.url)}><u>{resource.Title}</u></h6>
                                                                    </li>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                        <br></br>
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {this.state.search_directory.map((heading, index) => (
                                                <div key={index}>
                                                    <h5>{heading.Title}</h5>
                                                    <hr></hr>
                                                    <ul>
                                                        {heading.Resources.map((resource, index) => (
                                                            <div key={index} className="resource-container">
                                                                <li>
                                                                    <h6 className="resource-title" onClick={() => this.updateDocument(resource.url)}><u>{resource.Title}</u></h6>
                                                                </li>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                    <br></br>
                                                </div>
                                                ))}
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="documentation-container">
                            {this.state.loading?
                                <Spinner/>
                                :
                                <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
                                    {this.state.markdown}
                                </Markdown>
                            }
                        </div>
                    </div>
                </Container>
            </div>
                <Footer />
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default Resources;