import React from 'react';
import { CiClock1 } from "react-icons/ci";

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

const padZero = (num) => {
  return num < 10 ? `0${num}` : num;
};

const TimeDisplay = ({ totalSeconds, catalogTime }) => {
  const { hours, minutes, seconds } = formatTime(totalSeconds);

  return (
    <div>
      {catalogTime === true ? 
          <span>
            <CiClock1 /> {hours !== 0 && `${hours}:`}
            {hours === 0 && minutes !== 0 && `${padZero(minutes)}:`}{padZero(seconds)}
          </span> 
            : 
            <span className= "time">
              <CiClock1 /> {hours !== 0 && `${hours}:`}
              {hours === 0 && minutes !== 0 && `${padZero(minutes)}:`}{padZero(seconds)}
            </span>
      }
    </div>
  );
};

export default TimeDisplay;