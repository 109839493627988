import React, { Component } from 'react';
import {getCookieConsentValue} from "react-cookie-consent";
import { Container, Button, Spinner } from 'react-bootstrap';
import { ACADEMY_DOMAIN } from '../Variables/APIEndPoints/endpoints';
import Cookies from 'js-cookie';
import { IoArrowBackCircleSharp } from "react-icons/io5";
import '../componentCSS/courseOverview.css';
import TimeDisplay from './TimeDisplay';
import { IoCheckboxOutline } from "react-icons/io5";
import { FaSquareCheck } from "react-icons/fa6";

class CourseOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseID: this.props.id,
            course: null,
            logged_out: false,
            video_url: null,
            loading: true,
            current_video_id_playing: "",
            watched_video_ids: []
        }
        this.changeVideo = this.changeVideo.bind(this);
        this.handleVideoEnd = this.handleVideoEnd.bind(this);
        this.nextVideo = this.nextVideo.bind(this);
    }

    async componentDidMount() {
        let requestOptions = {}
        // Logging the user in if they have cookies enabled or session is on going.
        if(getCookieConsentValue() === "true"){
            if(Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
                requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
                };
            }
        }   
        // cookie consent was false.
        else{
            if(sessionStorage.getItem('jwtToken') !== undefined && sessionStorage.getItem('jwtToken') !== ""){
                requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
                };
            }
        }
        const response = await fetch(ACADEMY_DOMAIN + "/course?course_id=" + this.props.id, requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({course: data.course_metadata_array});
            const sections = this.sortSectionsAndVideos(data.course_metadata_array);
            if(getCookieConsentValue() === "true"){
                if(Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
                    requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
                    };
                }
            }   
            // cookie consent was false.
            else{
                if(sessionStorage.getItem('jwtToken') !== undefined && sessionStorage.getItem('jwtToken') !== ""){
                    requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
                    };
                }
            }
            const video_response = await fetch(ACADEMY_DOMAIN + "/video?video_id=" + data.course_metadata_array[0]['Course-Sections'][0].Course_Videos[0].video_id , requestOptions);
            const video_data = await video_response.json();
            if(video_response.ok){
                this.setState({video_url : video_data.signed_url});
                this.setState({current_video_id_playing : data.course_metadata_array[0]['Course-Sections'][0].Course_Videos[0].video_id});
            }
            else{
                this.setState({logged_out : true});
            }
        }
        else if(response.status === 401){
            this.setState({logged_out : true});
        }
        this.setState({loading : false});
    }

    sortSectionsAndVideos(course) {
        const sections = course[0]['Course-Sections'];
        sections.sort((a,b) => a.course_section_index - b.course_section_index);
        for(let i = 0; i < sections.length; i++){
            sections[i].Course_Videos.sort((a,b) => a.video_order_index - b.video_order_index);
        }
        return sections;
    }

    async changeVideo(video_id) {
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
            };
        }   
        // cookie consent was false.
        else{
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
            };
        }
        const video_response = await fetch(ACADEMY_DOMAIN + "/video?video_id=" + video_id , requestOptions);
        const video_data = await video_response.json();
        if(video_response.ok){
            this.setState({video_url : video_data.signed_url});
            this.setState({current_video_id_playing : video_id});
        }
        else{
            // DO SOMETHING IF FAIL.
            console.log(video_data);
        }
    }

    async nextVideo() {
        let next_id = false;
        for(let i = 0; i < this.state.course[0]['Course-Sections'].length; i++) {
            for(let j = 0; j < this.state.course[0]['Course-Sections'][i].Course_Videos.length; j++){
                if(this.state.course[0]['Course-Sections'][i].Course_Videos[j].video_id === this.state.current_video_id_playing){
                    next_id = true;
                }
                else if(next_id === true){
                    await this.changeVideo(this.state.course[0]['Course-Sections'][i].Course_Videos[j].video_id);
                    next_id = false;
                }
            }
        }
    }

    async handleVideoEnd() {
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({video_id : this.state.current_video_id_playing})
            };
        }   
        // cookie consent was false.
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({video_id : this.state.current_video_id_playing})
            };
        }
        const response = await fetch(ACADEMY_DOMAIN + "/video-watched", requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({watched_video_ids : [...this.state.watched_video_ids, this.state.current_video_id_playing]});
        }
        else{
            this.setState({logged_out : true});
        }
        
    }

    render() {
        return (
            <div className="overview-page-container">
                <Container className="overview-page-container">
                    {this.state.loading ? <div className="center"><Spinner /></div> : <>
                        {this.state.logged_out ? 
                            <div className="center">
                                <br></br>
                                <h1>You've been logged out. Please log back in!</h1>
                                <Button size="lg" href="/account">Login</Button>
                            </div> :
                            <div className="course-overview-container overview-page-container">
                                {this.state.course !== null && this.state.video_url !== null && 
                                <div className="overview-page-container">
                                    <h4><Button href="/academy" className="back-button" variant="light"><IoArrowBackCircleSharp /> To Academy</Button></h4><h1 className="current-video-name">{this.state.course[0].course_name}</h1>
                                    
                                    
                                    <div className="video-container">
                                        <video className="main-video" controls controlsList="nodownload" id="current-video" src={this.state.video_url} onEnded={this.handleVideoEnd}>
                                            <source type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="description-container">
                                        <h4 className="float-left">Course Description</h4>
                                        <Button className="next-video-button float-right" onClick={this.nextVideo}>Next Video</Button>
                                        <div className="break-div"></div>
                                        <hr></hr>
                                        <p>{this.state.course[0].course_description}</p>
                                    </div>
                                    <div className="sidebar-guide">
                                        <div className="guide-heading">
                                            <h4><Button href="/academy" className="back-button-sidebar" variant="light"><IoArrowBackCircleSharp /> To Academy</Button></h4>
                                        </div>
                                        <div className="sections">
                                            <>
                                            {this.state.course[0]['Course-Sections'].map((section, index) => (
                                                <div key={index} className="sections-container">
                                                    <div className="course-section-name">
                                                        <h5><b>{section.course_section_name}</b></h5>
                                                    </div>
                                                    {section.Course_Videos.map((video, index) => 
                                                        <div key={index} className={this.state.current_video_id_playing === video.video_id ? "video-name-playing" : "video-name"} onClick={() => this.changeVideo(video.video_id)}>
                                                            <h6>
                                                                {(this.state.watched_video_ids.includes(video.video_id) === false && video.Watched_Videos.length === 0 ) ? <IoCheckboxOutline className="video-watched"/> : <FaSquareCheck className="video-watched"/>}
                                                                {video.video_order_index}. {video.video_name}
                                                            </h6>
                                                            <TimeDisplay totalSeconds={video.length_seconds}/>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                            }
                                            </>
                                            <div className="end-of-sections">
                                                <h6 className="center">End</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div> 
                        }</>
                    }
                </Container>
            </div>
        )
    }
}

export default CourseOverview;