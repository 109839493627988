import React, { Component } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { HOSTING_DOMAIN } from '../Variables/APIEndPoints/endpoints';


class ChangecPanelUsernameForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe_subscription_id: this.props.stripe_subscription_id,
            domain: this.props.domain,
            new_username: "",
            changeUsernameLoading: false,
            changeUsernameError: false,
            changeUsernameErrorText: "",
            new_manage_url: "",
            success: false
        }
        this.changecPanelUsername = this.changecPanelUsername.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async changecPanelUsername(event){
        event.preventDefault();
        this.setState({changeUsernameLoading : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({new_username : this.state.new_username, domain : this.state.domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({new_username : this.state.new_username, domain : this.state.domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/change-username', requestOptions);
        const data = await response.json();
        if(data.Error){
            this.setState({changeUsernameError : true});
            this.setState({changeUsernameErrorText : data.reason});
            this.setState({changeUsernameLoading : false});
        }
        else{
            this.setState({changeUsernameLoading : false});
            this.setState({success: true});
            this.setState({new_manage_url : data.new_manage_url});
        }
    }

    handleClose(){
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.changecPanelUsername}>
                    <Form.Group className="mb-3">
                        <Form.Label>New Username</Form.Label>
                        <Form.Control type="text" placeholder="username" value={this.state.new_username} onChange={e => this.setState({new_username : e.target.value})}/>
                        <Form.Text className="text-muted">
                            This will replace the current username of your cPanel account.
                            <ul>
                                <li>Usernames cannot contain more than 16 characters.</li>
                                <li>Usernames cannot begin with a digit (0–9) or the string "test".</li>
                            </ul>
                            {this.state.changeUsernameError && <p className="error">Error: {this.state.changeUsernameErrorText}</p>}
                        </Form.Text>
                    </Form.Group>
                    <Button className="muffin-button" type="submit" disabled={this.state.changeUsernameLoading}>
                        Submit {this.state.changeUsernameLoading && <Spinner size='sm'/>}
                    </Button>
                    {this.state.changeUsernameLoading && <p>This can take a while. Don't exit or refresh the page.</p>}
                </Form>

                <Modal show={this.state.success} onHide={this.handleClose}>
                    <Modal.Header className="success"><b>Success</b></Modal.Header>
                        <Modal.Body>Your username has been changed</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ChangecPanelUsernameForm;