import React, { Component } from 'react';

class HelpfulVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alreadyHosting: this.props.alreadyHosting
        }
    }

    render() {
        return (
            <div>
                {this.state.alreadyHosting?
                    <>
                        <h2>Code</h2>
                        <br></br>
                        <div className="center">
                            <h3>How To Host Your React Web Application</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/An_rmz5nrdc?si=xefd0bEBipwrB_FS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            <br></br>
                            <br></br>
                            <h3>How To Host Your HTML / CSS / JavaScript Website</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/W0bPqVnEyQ8?si=fjgYRvcPZtWtNdNf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                        </div>
                        
                        <h2>No Code</h2>
                        <div className="center">
                            <h3>How To Get Started With SiteJet</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/V2Yqpd26GK8?si=FM2EXJBoGAHo6y3-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            <br></br>
                            <br></br>
                            <h3>How To Get Started With WordPress</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/q5EdZYkc7qw?si=C2lCC2bnSDIAbRLW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                        </div>
                        </>
                    :
                    <>
                        <h2>Get Started Hosting</h2>
                        <div className="center">
                            <h3>How To Start Hosting With Your Own Domain</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/e21p6uDu4yg?si=cObdGQWik689OvHR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            <br></br>
                            <br></br>
                            <h3>How To Start Hosting With A Free Domain</h3>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/hzcNXj5W76w?si=wsmTaMMPj_0iuCTn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                        </div>
                        </>
                }
            </div>
        )
    }
}

export default HelpfulVideos;