import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import HomeScreen from './components/HomeScreen';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Resources from './components/Resources';
import Account from './components/Account';
import SuccessfulPurchase from './components/SuccessfulPurchase';
import SubscriptionDeleted from './components/SubscriptionDeleted';
import EmailVerified from './components/EmailVerified';
import VerifyStudentEmail from './components/VerifyStudentEmail';
import TermsOfServiceAndPrivacyPolicy from './components/TermsOfServiceAndPrivacyPolicy';
import PageNotFound from './components/PageNotFound';
import VerifyEmailCode from './components/VerifyEmailCode';
import './App.css';
import ManageDomain from './components/ManageDomain';
import About from './components/About';
import Academy from './components/Academy';
import Course from './components/AcademyComponents/Course';
import ChangeProducts from './components/changeProductComponents/ChangeProducts';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/resources" element={<Resources />}/>
        <Route path="/account" element={<Account />}/>
        <Route path="/successful-purchase" element={<SuccessfulPurchase />}/>
        <Route path="/subscription-deleted" element={<SubscriptionDeleted/>}/>
        <Route path="/email-verified" element={<EmailVerified/>}/>
        <Route path="/verify-student-email" element={<VerifyStudentEmail/>}/>
        <Route path="/terms-of-service-and-privacy-policy" element={<TermsOfServiceAndPrivacyPolicy/>}/>
        <Route path="/verify-email-code" element={<VerifyEmailCode/>}/>
        <Route path="/manage" element={<ManageDomain/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/academy" element={<Academy/>}/>
        <Route path="/course/:id" element={<Course />}/>
        <Route path="/change-sub/:id" element={< ChangeProducts/>}/>
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
