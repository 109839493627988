import React, { Component} from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import CookieConsent from "react-cookie-consent";
import { Button, Modal, Container } from 'react-bootstrap';
import { EMAIL_DOMAIN } from './Variables/APIEndPoints/endpoints';

class VerifyEmailCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailVerificationCode: "",
            verifyEmailErrorModal: false,
            verifyEmailError: ""
        }
        this.setEmailVerificationCode = this.setEmailVerificationCode.bind(this);
        this.SendEmailVerificationCode = this.SendEmailVerificationCode.bind(this);
        this.closeEmailVerificationErrorModal = this.closeEmailVerificationErrorModal.bind(this);
    }

    setEmailVerificationCode(event){
        this.setState({emailVerificationCode:event.target.value});
    }

    async SendEmailVerificationCode(){
        const response = await fetch(EMAIL_DOMAIN + '/verify-email-code?verificationID=' + this.state.emailVerificationCode);
        const data = await response.json();
        if(data.Success){
            window.location.href = data.url
        }
        else{
            this.setState({verifyEmailError : data.Error});
            this.setState({verifyEmailErrorModal : true});
        }
        console.log(data);
    }

    closeEmailVerificationErrorModal(){
        this.setState({verifyEmailErrorModal : false});
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container>
                    <div className="float-left">
                        <h1>Please Enter Your 20 Character Code:</h1>
                        <p>It has been sent to the email you provided please wait a maximum of 10 minutes. <br></br>Please check your spam folder. Please contact customer support at <b>info@muffinmanhosting.com</b><br></br>if there are any issues.</p>
                        <input type="text" maxLength={20} value={this.emailVerificationCode} onChange={this.setEmailVerificationCode}/>{' '}<Button onClick={this.SendEmailVerificationCode}>Submit</Button>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>


                <Modal show={this.state.verifyEmailErrorModal} onHide={this.closeEmailVerificationErrorModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="error">{this.state.verifyEmailError}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeEmailVerificationErrorModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default VerifyEmailCode;