import React, { Component } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import { Button, Container, Image } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import './componentCSS/homeScreen.css';
import { IoStarSharp } from "react-icons/io5";
import { CiGift } from "react-icons/ci";
import { FaCpanel } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { FaNode } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiMysql } from "react-icons/si";


class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <div>
                    <Container className="container-body">
                        <h1 className="center hero-heading">Power Your Website. Elevate Your Skills.</h1>
                        <h3 className="center hero-subheading">Affordable hosting and expert-led courses, all in one platform</h3>
                        <h4 className="center bundle-notificaton"><CiGift className="gift"/> Get your first month FREE! <CiGift className="gift"/></h4>
                        <br></br>
                        <div className="center">
                            <Button className="muffin-button" size="lg" href="/pricing">Start Today</Button>
                        </div>
                        <div className="hosting-section">
                            <div className="image-container">
                                <Image src="https://muffinmanhosting.com/images/hosting.png" className="landing-image"/>
                            </div>
                            <div className="image-text center">
                                <h1 className="hosting-section-text">Host Your Website in Minutes</h1>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="pricing-section">
                            <div className="image-text-left center">
                                <h1 className="hosting-section-text">For a Reasonable Price</h1>
                            </div>
                            <div className="image-container-right-pricing">
                                <Image src="https://muffinmanhosting.com/images/pricing.png" className="landing-image"/>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="academy-section">
                            <div className="image-container-right">
                                <Image src="https://muffinmanhosting.com/images/academy.png" className="landing-image"/>
                            </div>
                            <div className="image-text-left center">
                                <h1 className="hosting-section-text">And Learn From Experts (Coming soon)</h1>
                            </div>
                        </div>
                        <hr></hr>
                            <div className="center">
                                <h1>What is Website Hosting?</h1>
                                <br></br>
                            <iframe className="whatIsWebsiteHostingVideo" src="https://www.youtube.com/embed/GRADAN-jhiE?si=R4T5Jfguh762avdY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                            </div>
                            
                        <hr></hr>
                        <div className="features-section">
                            <h3>Features</h3>
                            <ol className="features-list">
                                <li><IoStarSharp className="star"/><b> No domain, no problem!</b> - Get a free subdomain with any hosting purchase.</li>
                                <li><IoStarSharp className="star"/><b> Don't know how to code?</b> - Use our one click WordPress install or SiteJet Engine to create a website today.</li>
                                <li><IoStarSharp className="star"/><b> Planning to make a hit website or company?</b> - We will scale with you! Start with our basic plan and upgrade as you grow.</li>
                                <li><IoStarSharp className="star"/><b> Here to learn?</b> - Join our Academy where industry experts show you how to be the best in the web world.</li>
                                <li><IoStarSharp className="star"/><b> Want to create a Full-Stack app?</b> -  With any of our hosting plans you can host your Website, Database, and API's with ease.</li>
                            </ol>
                            <br></br>
                            <br></br>
                            <div className="center">
                                <h3>work with and learn the most popular web technologies</h3>
                                <h2 className="icon"><FaCpanel className="cpanel"/> {' '}
                                <FaWordpress/> {' '}
                                <FaNode className="nodejs"/> {' '}
                                <FaPython className="python"/> {' '}
                                <SiMysql className="database"/></h2>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="features-section">
                            <h3>Perfect for Students & Startups!</h3>
                            <div className="students-container">
                                <h4 className="center">STUDENTS</h4>
                                <p className="explain-text center">We understand that not every student has the financial freedom to host a website. That's why we give a discount to students for all our products! Weather you just want to host something you made, have a side hustle, or have a place to put your online resume / portofilio Muffin Man Hosting is the right choice for you.</p>
                            </div>
                            <div className="startups-container">
                                <h4 className="center">STARTUPS</h4>
                                <p className="explain-text center">
                                Looking to launch your startup into the digital stratosphere? Look no further than Muffin Man Hosting, your ticket to seamless online success! With unbeatable pricing that won't break the bank, we pave the way for startups to thrive without draining their resources. But affordability is just the beginning. At Muffin Man Hosting, we're more than just a hosting service—we're your growth partner. As your startup blossoms and expands, so do we, ensuring that your hosting needs are always met, no matter how big your dreams become. Join the Muffin Man Hosting family today and let's turn your startup aspirations into digital reality!
                                    </p>
                            </div>
                            <div className="break-div"></div>
                        </div>
                        <hr></hr>
                        <div className="features-section">
                            <h3>Students</h3>
                            <h4 className="center market-text">WE KNOW THE JOB MARKET IS TOUGH</h4>
                            <p className="center explain-text">With our platform you can create and host multiple <b>full-stack apps</b> for employers to see! Make your job applications stand out by signing up for Muffin Man Hostings <b>$1.99 Student plan</b> today.</p>
                        </div>
                        <div className="center">
                            <Button className="start-now-button muffin-button" size="lg" href="/pricing">Start Today</Button>
                        </div>
                    </Container>
                    <div className="clearDiv"></div>
                    <Footer/>
                </div>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default HomeScreen;