import React, { Component } from 'react';
import { Container, Spinner, Button } from 'react-bootstrap';
import Footer from '../Footer';
import NavBar from '../NavBar';
import { STRIPE_DOMAIN } from '../Variables/APIEndPoints/endpoints';
import PlanChangeCard from './PlanChangeCard';
import { FaArrowLeft } from "react-icons/fa";

class OtherProductsGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionID: this.props.subscriptionID,
            loading: true,
            loggedOut: false,
            plans: null
        }
    }

    async componentDidMount() {
        const response = await fetch(STRIPE_DOMAIN + "/get-all-products");
        if(response.ok) {
            const data = await response.json();
            const products = data.Products;
            let product_groups = {};
            for(let i = 0; i < products.length; i++){
                if(product_groups[products[i].group_id] === undefined){
                    product_groups[products[i].group_id] = [products[i]]
                }
                else{
                    product_groups[products[i].group_id].push(products[i]);
                }
            }
            this.setState({product_groups : product_groups});
            this.setState({products : data.Products});
            this.setState({loading: false});
        }
        else{
            this.setState({loading: false});
            this.setState({error_loading : true});
        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container className="container-body">
                    <div>
                        {this.state.loading ? <div className="center"><Spinner /></div> : 
                        <div>
                            {this.state.loggedOut ? <div><h1>You've been logged out</h1> <Button href="/account" className="muffin-button">To Account</Button></div> :
                            
                            <div>
                                <Button className="muffin-button" href="/account"> <FaArrowLeft /> To Account</Button>
                                <h1>Choose a new product</h1>
                                <div classname="grid-wrapper">
                                {Object.keys(this.state.product_groups).length !== 0 && Object.keys(this.state.product_groups).length !== undefined && Object.keys(this.state.product_groups).map((product_group, index) => (
                                    <div key={index}>
                                        {index === 0 && <h1 className="center"><b>Student Pricing</b></h1>}
                                        {index === 1 && <h1 className="center"><b>Non-Student Pricing</b></h1>}
                                        {index === 2 && <h1 className="center"><b>Super Pricing</b></h1>}
                                        {index === 3 && <h1 className="center"><b>Epic Pricing</b></h1>}
                                        <br></br>
                                        <div className="grid-wrapper">
                                        {this.state.product_groups[product_group].map((product, product_index) => (
                                            <PlanChangeCard key={product_index} product={product} subscriptionID={this.state.subscriptionID}/>
                                        ))}
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                            }
                        </div>
                        }
                    </div>
                </Container>
                <Footer/>           
            </div>
        )
    }
}

export default OtherProductsGallery;