import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import '../footer.css';
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state ={
            homeScreen : this.props.homeScreen
        }
    }

    render() {
        return (
            <div>
                <div className="home-footer">
                    <Container>
                        <section className="bottom-grid">
                            <section className="footer-info-left">
                                <section className="footer-info_companyname">
                                    <b>Muffin Man Hosting</b>
                                </section>
                                <section>
                                    <a href="/about">About</a>
                                </section>
                                <section className="footer-info_policies">
                                    <a href="/terms-of-service-and-privacy-policy">Terms & Conditions / Privacy Policy</a>
                                </section>
                            </section>
                            <section className="footer-info-center">
                                <div>
                                    <b>Social Media</b>
                                </div>
                                    
                                <a href="https://www.instagram.com/muffin_man_hosting/">
                                    <FaInstagram style={{ fontSize: '40px' }}/>
                                </a>
                            
                                <a href="https://www.youtube.com/@MuffinManHosting">
                                    <FaYoutube style={{ fontSize: '40px' }}/>
                                </a>
                                <p>© 2024 Muffin Man Hosting. All rights reserved.</p>
                            </section>
                            <section className="footer-info-right">
                                <section>
                                    <b>Email Contact</b>
                                </section>
                                <section>
                                    <p>info@muffinmanhosting.com</p>
                                </section>
                            </section>
                        </section>
                    </Container>
                </div>

            </div>
        );
    }
}


export default Footer;