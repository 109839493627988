import React, { Component } from 'react';
import NavBar from './NavBar';
import { Button, Container } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import graphic from '../images/background.png';

class EmailVerified extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async componentDidMount() {
    }


    render() {
        return (
            <div>
                <NavBar />
                <div className="info-container">
                    <Container>
                        <div className="float-left">
                            <h3 className="account-created">Your Email Has Been Verified!</h3>
                            <Button className="rounded" href='/account' variant="primary" size="lg"><b>Start Now</b></Button>
                        </div>
                        <div className="images">
                            <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                        </div>
                    </Container>
                </div>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default EmailVerified;