import React, { Component} from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import CookieConsent from "react-cookie-consent";
import { Button, Container } from 'react-bootstrap';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container>
                    <div className="float-left">
                        <h1>Oops it appears this page doesn't exist!</h1>
                        <br></br>
                        <Button className="rounded" href='/account' variant="primary" size="lg"><b>Click to Return</b></Button>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default PageNotFound;