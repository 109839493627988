import React, { Component } from 'react';
import { Form, Button, Spinner, Modal} from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { HOSTING_DOMAIN } from '../Variables/APIEndPoints/endpoints';

class DeletecPanelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe_subscription_id: this.props.stripe_subscription_id,
            deletecPanelAccountLoading: false,
            deletecPanelAccountError: false,
            deletecPanelAccountErrorText: "",
            success: false,
            new_manage_url: ""
        }
        this.deletecPanelAccount = this.deletecPanelAccount.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async deletecPanelAccount(event){
        event.preventDefault();
        this.setState({deletecPanelAccountLoading : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/delete-cpanel-account', requestOptions);
        const data = await response.json();
        if(data.Error){
            this.setState({deletecPanelAccountError : true});
            this.setState({deletecPanelAccountErrorText : data.reason});
            this.setState({deletecPanelAccountLoading : false});
        }
        else{
            this.setState({deletecPanelAccountLoading : false});
            this.setState({success: true});
            this.setState({new_manage_url : data.new_manage_url});
        }
    }

    handleClose(){
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.deletecPanelAccount}>
                    <Form.Group className="mb-3">
                        <Form.Text className="text-muted">
                            <b>This will delete your entire cPanel account. Proceed with caution as you will not get the account back.</b>
                        </Form.Text>
                        {this.state.deletecPanelAccountError && <p className="error">Error: {this.state.deletecPanelAccountErrorText}</p>}
                    </Form.Group>
                    <Button variant='danger' type="submit" disabled={this.state.deletecPanelAccountLoading}>
                        Delete cPanel Account {this.state.deletecPanelAccountLoading && <Spinner size='sm'/>}
                    </Button>
                    {this.state.deletecPanelAccountLoading && <p>This can take a while. Don't exit or refresh the page.</p>}
                </Form>
                <Modal show={this.state.success} onHide={this.handleClose}>
                    <Modal.Header className="success"><b>Success</b></Modal.Header>
                        <Modal.Body>Your cPanel account has been deleted</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default DeletecPanelForm;