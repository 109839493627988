import React, { Component } from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import CookieConsent from "react-cookie-consent";
import Footer from './Footer';
import { Container } from 'react-bootstrap';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container className="container-body">
                    <div className="float-left">
                        <h1>Contact</h1>
                        <p>Email us at: <b>info@muffinmanhosting.com</b></p>
                        <p><b>Hours of Operation:</b> 9am-5pm EST</p>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>
                <div className="clearDiv"></div>
                <Footer/>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default Contact;