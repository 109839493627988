import React, { Component } from 'react';
import NavBar from './NavBar';
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import { Container, Spinner, Button } from 'react-bootstrap';
import Footer from './Footer';
import { ACADEMY_DOMAIN } from './Variables/APIEndPoints/endpoints';
import Cookies from 'js-cookie';
import CourseCatalog from './AcademyComponents/CourseCatalog';
import { TypeAnimation } from 'react-type-animation';
import './componentCSS/academyScreen.css';

class Academy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn : false,
            requiresSubscription: false,
        }
    }

    // async componentDidMount() {
    //     let requestOptions = {};
    //     let makeCall = true;
    //     // Logging the user in if they have cookies enabled or session is on going.
    //     if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
    //         requestOptions = {
    //             method: 'GET',
    //             headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
    //         }
    //     }   
    //     // cookie consent was false.
    //     else{
    //         requestOptions = {
    //             method: 'GET',
    //             headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
    //         };
    //     }
    //     if(makeCall){
    //         const response = await fetch(ACADEMY_DOMAIN + "/has-academy-subscription", requestOptions);
    //         const data = await response.json();
    //         if(response.status === 200){
    //             this.setState({loggedIn : true});
    //             if(data.academySubscription === true){
                    
    //             }
    //             else{
    //                 this.setState({requiresSubscription : true});
    //             }
    //         }
    //         else{
    //             this.setState({loggedIn: false});
    //         }
    //     }
    //     this.setState({loading: false});
    // }

    render() {
        return (
            <div>
                <NavBar />
                <Container className="container-body">
                    {/* <h1 className="">Academy</h1>
                    <br></br>
                    {this.state.loading === true &&
                        <div className="center">
                            <Spinner animation="border" variant="primary" size="lg"/>
                        </div>
                    }
                    {this.state.loading === false && this.state.loggedIn === false && 
                        <>
                            <div className="center">
                            <h1 className="academy-sub-heading"><b>LOGIN TO ACCESS THE ACADEMY</b></h1>
                            <br></br>
                            <br></br>
                            <div className="grid-wrapper-academy-sub">
                                    <p className="subscription-tag-line"><b>
                                    Get exclusive access to video tutorials on <br></br>
                                    <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        ' website creation',
                                        1500, // wait 1s before replacing "Mice" with "Hamsters"
                                        ' website hosting',
                                        1500,
                                        ' api deployment',
                                        1500
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    // style={{ fontSize: '2em', display: 'inline-block' }}
                                    repeat={Infinity}
                                    /></b></p>
                                    <img src="https://www.muffinmanhosting.com/images/academy-subscription-img.png" alt="academy" className="academy-sub-img"/>
                                </div>
                                <br></br>
                                <Button href="/account" size="lg" className="to-pricing-button">To Account</Button>
                            </div>
                        </>
                    }
                    {this.state.loading === false && this.state.loggedIn === true && this.state.requiresSubscription === true && 
                        <>
                            <div className="center">
                                <h2 className="academy-sub-heading"><b>SUBSCRIBE TO THE ACADEMY</b></h2>
                                <br></br>
                                <br></br>
                                <div className="grid-wrapper-academy-sub">
                                    <p className="subscription-tag-line"><b>
                                    Get exclusive access to video tutorials on <br></br>
                                    <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        ' website creation',
                                        1500, // wait 1s before replacing "Mice" with "Hamsters"
                                        ' website hosting',
                                        1500,
                                        ' api deployment',
                                        1500
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    // style={{ fontSize: '2em', display: 'inline-block' }}
                                    repeat={Infinity}
                                    /></b></p>
                                    <img src="https://www.muffinmanhosting.com/images/academy-subscription-img.png" alt="academy" className="academy-sub-img"/>
                                </div>
                                <br></br>
                                <Button href="/pricing" size="lg" className="to-pricing-button">To Pricing</Button>
                            </div>
                        </>
                    }
                    {this.state.loading === false && this.state.loggedIn === true && this.state.requiresSubscription === false && 
                        <>
                            <CourseCatalog />
                        </>
                    }
                    <br></br> */}
                    <h1>Academy</h1>
                    <h2 className="center">The Academy is coming soon!</h2>
                    <h1 className="center">&#128513;</h1>
                </Container>
                <div className="clearDiv"></div>
                <div>
                    
                </div>
                <Footer homeScreen={false}/>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default Academy;