import React, { Component } from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import { Button, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

class SuccessfulPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
        
    }

    render() {
        return(
            <div>
                <NavBar />
                <Container>
                    <div className="float-left">
                        <h1>CONGRATULATIONS!</h1>
                        <p>Welcome to the Muffin Man family!</p>
                        <p><b>Next Steps:</b></p>
                        <ol>
                            <li>
                                <a href="/resources?path=%2Fdocs%2FHosting%2Fhosting_own_domain.md" target="_blank" rel="noreferrer"><b>Get Started With Your Own Domain</b></a> 
                                <br></br>
                                <br></br>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/e21p6uDu4yg?si=cObdGQWik689OvHR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            </li>
                            <br></br>
                            <li>
                                <a href="/resources?path=%2Fdocs%2FHosting%2Fhosting_free_domain.md" target="_blank" rel="noreferrer"><b>Get Started With a Free Domain</b></a>
                                <br></br>
                                <br></br>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/hzcNXj5W76w?si=wsmTaMMPj_0iuCTn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            </li>
                        </ol>
                        <Link to="/account"><Button className="muffin-button">Back To Account</Button></Link>
                        <p></p>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default SuccessfulPurchase;
