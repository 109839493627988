import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import icon from '../images/icon.png';
import { MdAccountCircle } from "react-icons/md";
import { MdLibraryBooks } from "react-icons/md";
import { LiaServerSolid } from "react-icons/lia";
import { MdMarkEmailUnread } from "react-icons/md";
import { GiInfo } from "react-icons/gi";
import { IoHomeSharp } from "react-icons/io5";
import { PiStudentBold } from 'react-icons/pi';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state ={
            
        }
    }

    render() {
        return (
            <div>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="/"><img style={{ width: 30, height: 30 }} src={icon} alt="Muffin Man Logo" /><b> Muffin Man Hosting</b></Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Nav>
                                {/* <Nav.Item>
                                    <Nav.Link href="/"><IoHomeSharp /> <b>Home</b></Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link href="/contact"><MdMarkEmailUnread /> <b>Contact</b></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/account"><MdAccountCircle /> <b>Account</b></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/academy"><PiStudentBold /> <b>Academy</b></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/resources"><MdLibraryBooks /> <b>Resources</b></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/about"><GiInfo /> <b>About</b></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link href="/pricing"><LiaServerSolid /> <b>Pricing</b></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}


export default NavBar;