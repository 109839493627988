import React, { Component } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { HOSTING_DOMAIN } from '../Variables/APIEndPoints/endpoints';


class ChangecPanelPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionID: this.props.subscriptionID,
            sendingEmail: false,
            showErrorModal: false,
            errorMessage: "",
            showChangePasswordModal: false,
            passwordChangeError: false,
            passwordChangeErrorMessage: "",
            new_password: "",
            new_password_repeated: "",
            emailToken: "",
            showPasswordChangeSuccessModal: false,
            checkingPassChange: false
        }
        this.sendChangePasswordEmail = this.sendChangePasswordEmail.bind(this);
        this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
        this.handleChangePasswordModalClose = this.handleChangePasswordModalClose.bind(this);
        this.handleSuccessClose = this.handleSuccessClose.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    async sendChangePasswordEmail() {
        this.setState({sendingEmail : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({stripeSubscription: this.state.subscriptionID})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({stripeSubscription: this.state.subscriptionID})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/send-change-password-email', requestOptions);
        const data = await response.json();
        if(response.ok){
            this.setState({showChangePasswordModal : true});
            this.setState({sendChangePasswordEmail : false});
        }
        else if(response.status === 401){
            window.location.reload();
        }
        else if(response.status === 403 || response.status === 500){
            this.setState({errorMessage : data.reason});
            this.setState({showErrorModal : true});
            this.setState({sendChangePasswordEmail : false});
        }
        this.setState({sendingEmail : false});
    }

    async changePassword() {
        this.setState({checkingPassChange : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({token: this.state.emailToken, password: this.state.new_password})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({token: this.state.emailToken, password: this.state.new_password})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/change-password', requestOptions);
        const data = await response.json();
        if(response.ok){
            if(data.result.Error === true){
                this.setState({passwordChangeError : true});
                this.setState({passwordChangeErrorMessage : data.result.reason});
            }
            else{
                this.handleChangePasswordModalClose();
                this.setState({showPasswordChangeSuccessModal : true});
            }
        }
        else if(response.status === 401){
            window.location.reload();
        }
        else if(response.status === 403 || response.status === 500 || response.status === 480){
            this.setState({passwordChangeError : true});
            this.setState({passwordChangeErrorMessage : data.reason});
        }
        this.setState({checkingPassChange : false});
    }

    handleErrorModalClose() {
        this.setState({showErrorModal : false});
        this.setState({errorMessage : ""});
    }

    handleChangePasswordModalClose() {
        this.setState({new_password : ""});
        this.setState({new_password_repeated : ""});
        this.setState({emailToken : ""});
        this.setState({passwordChangeErrorMessage : ""});
        this.setState({passwordChangeError : false});
        this.setState({showChangePasswordModal : false});

    }

    handleSuccessClose() {
        this.setState({showPasswordChangeSuccessModal : false});
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Text className="text-muted">
                            <b>Click here to change your cPanel password. Clicking this button will send an email to your gmail.</b>
                        </Form.Text>
                    </Form.Group>
                    {this.state.sendingEmail?
                        <Button className="muffin-button" disabled="true">
                            <Spinner size='sm'></Spinner> Change Password
                        </Button>
                        :
                        <Button variant="primary" className="muffin-button" onClick={this.sendChangePasswordEmail}>
                            Change Password
                        </Button>}
                </Form>

                <Modal show={this.state.showErrorModal} onHide={this.handleErrorModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="error">Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessage}</Modal.Body>
                </Modal>

                <Modal show={this.state.showChangePasswordModal}>
                    <Modal.Header>
                        <Modal.Title>Change cPanel Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>An email with a token has been sent to your gmail. Please use that token to change your cPanel password. (Check your spam)</p>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-3"><b>New Password</b></Form.Label>
                                <Form.Control type="password" value={this.state.new_password} className="user-info-control" onChange={e => this.setState({new_password : e.target.value})}/>
                                <Form.Text className="text-muted">
                                    Your new cPanel password.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-3"><b>New Password - Repeated</b></Form.Label>
                                <Form.Control type="password" value={this.state.new_password_repeated} className="user-info-control" onChange={e => this.setState({new_password_repeated : e.target.value})}/>
                                <Form.Text className="text-muted">
                                    Your new cPanel password.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Text className='text-muted'>
                                    Password Requirements:
                                    <ul>
                                        <li>Must be at least 10 characters long</li>
                                        <li>Cannot contain the character '&'</li>
                                    </ul>
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Text className='text-muted'>
                                {(this.state.new_password_repeated !== "" || this.state.new_password !== "") && this.state.new_password !== this.state.new_password_repeated ?
                                    <span className="error">Your passwords don't match</span>
                                    :
                                    <span></span>}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="mb-3"><b>Email Token</b></Form.Label>
                                <Form.Control type="text" value={this.state.emailToken} className="user-info-control" onChange={e => this.setState({emailToken : e.target.value})}/>
                                <Form.Text className="text-muted">
                                    Your new cPanel password.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Text className='text-muted'>
                                    {this.state.passwordChangeError ? <span className="error">{this.state.passwordChangeErrorMessage}</span> : 
                                        <span></span>
                                    }
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={this.handleChangePasswordModalClose}>Close</Button>{' '}
                        {this.state.checkingPassChange ? 
                            <Button variant='success' disabled="true">
                                <Spinner size='sm'></Spinner> Submit
                            </Button>
                            :
                            <span>
                                {this.state.new_password !== "" && this.state.new_password === this.state.new_password_repeated ? <Button variant='success' onClick={this.changePassword}>Submit</Button> : <Button variant='success' disabled="true">Submit</Button>}
                            </span>
                        }
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showPasswordChangeSuccessModal} onHide={this.handleSuccessClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="success">Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your password was changed successfully!</Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ChangecPanelPasswordForm;