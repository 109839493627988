import React, { Component } from 'react';
import {getCookieConsentValue} from "react-cookie-consent";
import { Card, Button } from 'react-bootstrap';
import { ACADEMY_DOMAIN } from '../Variables/APIEndPoints/endpoints';
import Cookies from 'js-cookie';
import TimeDisplay from './TimeDisplay';

class CourseCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            tags: []
        }
    }

    async componentDidMount() {
        let requestOptions = {}
        // Logging the user in if they have cookies enabled or session is on going.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'GET',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')}
            };
        }   
        // cookie consent was false.
        else{
            if(sessionStorage.getItem('jwtToken') !== undefined && sessionStorage.getItem('jwtToken') !== ""){
                requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')}
                };
            }
        }
        const response = await fetch(ACADEMY_DOMAIN + "/all-courses", requestOptions);
        const data = await response.json();
        this.setState({courses: data.courses_metadata_array});
        this.setState({course_domain : data.courseURL});
        this.getTags(data.courses_metadata_array);
    }

    getTags(courses_metadata) {
        let tags = [];
        for(let i = 0; i < courses_metadata.length; i ++){
            if(!tags.includes(courses_metadata[i].tag_name)){
                tags.push(courses_metadata[i].tag_name);
            }
        }
        this.setState({tags : tags});
    }

    render() {
        return (
            <div>
                <p>Welcome to the Academy! We will be adding 2 Quick Start courses a month, 1 Learning course a month, and 1 Full Course every 5 months.</p>
                <br></br>
                <h1 className="in-progress">In Progress:</h1>
                <div className="grid-wrapper-academy-in-progress">
                    {this.state.courses !== undefined && this.state.courses.map((course) => (
                        <>
                        {course.watched_videos >= 1 && course.watched_videos !== course.lectures &&
                            <div key={course.course_id} className="">
                                <a href={this.state.course_domain + course.course_id} className="course-details">
                                    <Card className="course-card">
                                        <Card.Header className="">
                                            <img src={course.thumbnail_url} alt="course thumbnail" className="course-thumbnail"/>
                                        </Card.Header>
                                        <Card.Body className="course-details">
                                            <h4>{course.course_name}</h4>
                                            <ul className="description-list">
                                                <li><b>Type:</b> {course.course_type}</li>
                                                <li><b>Lectures:</b> {course.lectures}</li>
                                                <li><TimeDisplay catalogTime={true} totalSeconds={course.time}/></li>
                                                <li><b>Watched:</b> {course.watched_videos} / {course.lectures}</li>
                                                <li><b>Completed:</b> <b>{course.watched_videos !== course.lectures ? <span className="error">Not Complete</span> : <span className="success">Completed</span>}</b> </li>
                                                <li><b>Description:</b> {course.course_description}</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </div>
                        }
                        </>
                    ))}
                </div>
                <br></br>
                <h1 className="in-progress">Courses:</h1>
                <h4>Click to go:</h4>
                {this.state.courses !== undefined && this.state.tags.length !== 0 && this.state.tags.map((tag) => (
                    <div key={tag}>
                        <Button className="muffin-button">{tag}</Button>
                    </div>
                ))}
                {this.state.courses !== undefined && this.state.tags.length !== 0 &&
                    this.state.tags.map((tag) => (
                        <div key={tag}>
                            <h3 className="center">{tag}</h3>
                            <br></br>
                            <div className="grid-wrapper-academy">
                                {this.state.courses.map((course, index) => (
                                    <div key={course.course_id}>
                                        {tag === course.tag_name &&
                                            <div className="grow">
                                                <a href={this.state.course_domain + course.course_id} className="course-details">
                                                    <Card className="course-card">
                                                        <Card.Header className="">
                                                            <img src={course.thumbnail_url} alt="course thumbnail" className="course-thumbnail"/>
                                                        </Card.Header>
                                                        <Card.Body className="course-details">
                                                            <h4>{course.course_name}</h4>
                                                            <ul className="description-list">
                                                                <li><b>Type:</b> {course.course_type}</li>
                                                                <li><b>Lectures:</b> {course.lectures}</li>
                                                                <li><TimeDisplay catalogTime={true} totalSeconds={course.time}/></li>
                                                                <li><b>Watched:</b> {course.watched_videos} / {course.lectures}</li>
                                                                <li><b>Completed:</b> <b>{course.watched_videos !== course.lectures ? <span className="error">Not Complete</span> : <span className="success">Completed</span>}</b> </li>
                                                                <li><b>Description:</b> {course.course_description}</li>
                                                            </ul>
                                                        </Card.Body>
                                                    </Card>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                }
            <div className="clearDiv"></div>
            </div>
        )
    }
}

export default CourseCatalog;