import React, { Component } from 'react';
import NavBar from './NavBar';
import CookieConsent from "react-cookie-consent";
import { Container, Spinner } from 'react-bootstrap';
import Footer from './Footer';
import PlanPurchaseCard from './PurchaseHostingComponents/PlanPurchaseCard';
import { STRIPE_DOMAIN } from './Variables/APIEndPoints/endpoints';
import './PurchaseHostingComponents/PlanPurchaseCardscss.css';

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_groups : {},
            products: [],
            error_loading : false,
            error_reason : "",
            loading: true
        }
    }

    async componentDidMount() {
        const response = await fetch(STRIPE_DOMAIN + "/get-all-products");
        if(response.ok) {
            const data = await response.json();
            const products = data.Products;
            let product_groups = {};
            for(let i = 0; i < products.length; i++){
                if(product_groups[products[i].group_id] === undefined){
                    product_groups[products[i].group_id] = [products[i]]
                }
                else{
                    product_groups[products[i].group_id].push(products[i]);
                }
            }
            this.setState({product_groups : product_groups});
            this.setState({products : data.Products});
            this.setState({loading: false});
        }
        else{
            this.setState({loading: false});
            this.setState({error_loading : true});
        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container className="container-body">
                    <h1>Pricing</h1>
                    <section className="hosting-container">
                        <section className="hosting-text">
                        </section>
                    </section>
                    {this.state.loading && <Spinner className="center"/>}
                    <br></br>
                    <div className="clearDiv"></div>
                    <div>
                        {this.state.error_loading === true && 
                        <div className="center">
                            <h3 className="error">We're sorry, something went wrong and we can't load our products!</h3>
                            <h4>Check back soon!</h4>
                        </div>
                        }
                    </div>
                    <div>
                        {this.state.error_loading === false && Object.keys(this.state.product_groups).length !== 0 && Object.keys(this.state.product_groups).length !== undefined && Object.keys(this.state.product_groups).map((product_group, index) => (
                            <div key={index}>
                                {index === 0 && <h1 className="center"><b>Student Pricing</b></h1>}
                                {index === 1 && <h1 className="center"><b>Non-Student Pricing</b></h1>}
                                {index === 2 && <h1 className="center"><b>Super Pricing</b></h1>}
                                {index === 3 && <h1 className="center"><b>Epic Pricing</b></h1>}
                                <br></br>
                                <div className="grid-wrapper">
                                {this.state.product_groups[product_group].map((product, product_index) => (
                                    <PlanPurchaseCard key={product_index} product={product}/>
                                ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
                <Footer />
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default Pricing;