import React, { Component } from 'react';
import NavBar from './NavBar';
import graphic from '../images/background.png';
import CookieConsent from "react-cookie-consent";
import Footer from './Footer';
import { Container } from 'react-bootstrap';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <NavBar />
                <Container className="container-body">
                    <div className="float-left hosting-container">
                        <h1>About</h1>
                        <p>Welcome to Muffin Man Hosting, your gateway to affordable website hosting with a special focus on students. At Muffin Man Hosting, we believe that everyone should have access to reliable and affordable hosting solutions without any hidden catches or surprises. What you see is what you pay for, transparent and straightforward.</p>

                        <h3>Affordable Hosting for Everyone</h3>
                        <p>Muffin Man Hosting offers hosting packages at prices that won't break the bank. Whether you're an individual looking to launch a personal website or a startup with budget constraints, our hosting plans are designed to meet your needs without compromising on quality.</p>

                        <h3>Student-Friendly Pricing</h3>
                        <p>We understand the financial challenges students face, which is why we offer even better pricing for students. Our student-friendly pricing ensures that aspiring developers and entrepreneurs can afford top-notch hosting services without stretching their budgets.</p>

                        <h3>Support for Startups</h3>
                        <p>Startups often encounter financial hurdles, but with Muffin Man Hosting, you can focus on building your business without worrying about hosting costs. Our affordable pricing model empowers startups to thrive online without draining their resources.</p>

                        <h3>Muffin Academy: Your Path to Success</h3>
                        <p>At Muffin Man Hosting, we're committed to your success. That's why we offer the Muffin Academy—an exclusive resource hub packed with educational videos to bridge any knowledge gaps you may have. From creating full-stack applications to optimizing your website for search engines, Muffin Academy equips you with the skills you need to succeed in the digital world.</p>

                        <h3>Free Subdomains</h3>
                        <p>Not ready to purchase a domain name yet? No problem! Muffin Man Hosting offers free subdomains, allowing you to get started with your website without the need to invest in a custom domain right away. Simply choose a subdomain, and you're ready to launch your online presence hassle-free.</p>

                        <h3>Bundle and Save</h3>
                        <p>Maximize your savings by bundling Muffin Academy with our hosting plans. When you purchase the bundle, not only do you get access to premium hosting services, but you also enjoy a free month of Muffin Academy. It's the perfect way to kickstart your online journey while saving money.</p>

                        <h3>Join the Muffin Man Community Today!</h3>
                        <p>Whether you're a student, entrepreneur, or seasoned developer, Muffin Man Hosting welcomes you to join our community. Experience reliable hosting, student-friendly pricing, and access to valuable resources—all in one place. Start your online journey with Muffin Man Hosting and unleash your full potential!</p>
                    </div>
                    <div className="images">
                        <img className="graphic" src={graphic} alt="Muffin Man Images"/>
                    </div>
                </Container>
                <div className="clearDiv"></div>
                <Footer/>
                <CookieConsent
                    enableDeclineButton
                >This website uses cookies to enhance the user experience. </CookieConsent>
            </div>
        )
    }
}

export default About;