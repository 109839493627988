import React, { Component } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { IoCheckmarkCircle } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { STRIPE_DOMAIN } from '../Variables/APIEndPoints/endpoints';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import './PlanPurchaseCardscss.css';

class PlanPurchaseCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product : props.product,
            redirect_url: "",
            error_message: "",
            show_modal: false,
            modal_button_exists: false,
            modal_button_text : "",
            showAcceptTermsModal : false
        }
       this.openPurchaseScreen = this.openPurchaseScreen.bind(this);
       this.handleClose = this.handleClose.bind(this);
       this.handleTermsModalClose = this.handleTermsModalClose.bind(this);
       this.acceptTerms = this.acceptTerms.bind(this);
    }

    async openPurchaseScreen() {
        let requestOptions = {}
        // Logging the user in if they have cookies enabled or session is on going.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({'stripeProductPrice' : this.state.product.current_stripe_price_code})
            };
        }   
        // cookie consent was false.
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({'stripeProductPrice' : this.state.product.current_stripe_price_code})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + "/purchase-subscription", requestOptions);
        const data = await response.json();
        if(response.ok) {
            window.location.href = data.redirect_url;
        }
        else if(response.status === 401) {
            this.setState({error_message : data.reason});
            this.setState({modal_button_exists : true});
            this.setState({modal_button_text : data.button_text});
            this.setState({show_modal : true});
        }
        else if(response.status === 500) {
            this.setState({show_modal : true});
            this.setState({error_message : data.reason});
            this.setState({modal_button_exists : false});
        }
        else if(response.status === 480) {
            this.setState({showAcceptTermsModal : true});
        }
    }

    handleClose() {
        this.setState({show_modal : false});
    }

    handleTermsModalClose() {
        this.setState({showAcceptTermsModal : false});
    }

    async acceptTerms() {
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify({credentials : {'credential' : Cookies.get('jwtToken')}})
            };
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify({credentials : {'credential' : sessionStorage.getItem('jwtToken')}})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + '/accept-terms', requestOptions);
        const data = await response.json();
        if(response.ok) {
            this.setState({showAcceptTermsModal : false});
        }
        else{
            window.location.href = '/account';
        }
    }

    render() {
        return (
            <div>
                <Card className={this.state.product.best_value ? "product-body best-value" : "product-body plan-card-border"}>
                    {this.state.product.requiresStudentEmail && <Card.Header className={this.state.product.best_value ? "student-head plan-card-best-value-header" : "student-head plan-card-header"}>
                        <h5><b>{this.state.product.product_name}</b></h5>
                    </Card.Header>}
                    {this.state.product.requiresStudentEmail === false && <Card.Header className={this.state.product.best_value ? "non-student-head plan-card-best-value-header" : "non-student-head plan-card-header"}>
                        <h5><b>{this.state.product.product_name}</b></h5>
                    </Card.Header>}
                    <Card.Body>
                        <div className="float-left">
                            <div>
                                <h3 className="block">${this.state.product.price}</h3><p className="block">/mo</p>
                            </div>
                            <Button onClick={this.openPurchaseScreen}>Buy Now</Button>
                        </div>
                        {this.state.product.best_value && 
                            <div className="float-right best-value-tag">
                                <h4><b>Best Value!</b></h4>
                                <h5><b>1'st Month Free 🎉</b></h5>
                                
                            </div>    
                        }
                        <div className="clearDiv"></div>
                        <br></br>
                        <table>
                            <tr>
                                <td className="no-border"><b>Student Email Verification</b></td>
                                {this.state.product.requiresStudentEmail && 
                                    <td className="error rightTable"><b>Required</b></td>
                                }
                                {this.state.product.requiresStudentEmail === false &&
                                    <td className="success rightTable"><b>Not Required</b></td>
                                }
                            </tr>
                            {this.state.product.is_hosting_plan &&
                            <>
                            <tr>
                                <td className="no-border"><b>Free SSL / TLS</b></td>
                                {this.state.product.free_ssl_tls && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.free_ssl_tls === false && 
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="no-border"><b>SQL Databases</b></td>
                                <td className="rightTable">{this.state.product.number_sql_database}</td>
                            </tr>
                            <tr>
                                <td className="no-border"><b>subdomains</b></td>
                                <td className="rightTable">{this.state.product.number_subdomains}</td>
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>NodeJS API's</b></td>
                                {this.state.product.node_js_apis &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.node_js_apis === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>Python API's</b></td>
                                {this.state.product.python_apis && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.python_apis === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>1 Click WordPress Install</b></td>
                                {this.state.product.wordpress && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.wordpress === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>Storage</b></td>
                                <td className="rightTable">{this.state.product.gb_storage}GB</td>
                            </tr>
                            <tr className="bottomTable">
                                <td className="table-side no-border"><b>Free Domain Name</b></td>
                                {this.state.product.free_domain_name &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.free_domain_name === false && 
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            </>}
                            <tr className="bottomTable">
                                <td className="table-side no-border"><b>Academy Access</b></td>
                                {this.state.product.isAcademyPlan_code === true &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {(this.state.product.isAcademyPlan_code === undefined || this.state.product.isAcademyPlan_code === false) &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }   
                            </tr>
                        </table>
                    </Card.Body>
                </Card>

                <Modal show={this.state.show_modal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Sorry!</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>{this.state.error_message}</Modal.Body>
                    <Modal.Footer>
                        { this.state.modal_button_exists && 
                            <Button variant="primary" href="/account">
                                {this.state.modal_button_text}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showAcceptTermsModal} onHide={this.handleTermsModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Please Accept Our Terms & Conditions / Privacy Policy</Modal.Title>
                    </Modal.Header>
                        <Modal.Body><a href="https://muffinmanhosting.com/pdfs/Terms_And_Conditions.pdf" target="_blank" rel="noreferrer">Terms & Conditions / Privacy Policy</a></Modal.Body>
                    <Modal.Footer>
                        <Button variant='success' onClick={this.acceptTerms}>Accept</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default PlanPurchaseCard;