import React, { Component } from 'react';
import { Button, Modal, Form, Spinner, Card, Container, Image, FormGroup } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import icon from '../images/icon.png';
import { HOSTING_DOMAIN, EMAIL_DOMAIN, STRIPE_DOMAIN } from './Variables/APIEndPoints/endpoints';
import { IoCartOutline } from "react-icons/io5";
import './componentCSS/accountSideBar.css';
import './componentCSS/accountInfo.css';
import { FaGears } from "react-icons/fa6";
import Footer from './Footer';

class AccountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            email: this.props.email,
            picture: this.props.picture,
            isStudent: this.props.studentEmailVerified,
            verifyStudentEmailModal: false,
            hosting: [],
            domainName: "",
            verifyStudentEmailLoading: false,
            studentEmailAddress: "",
            studentEmailAddressVerificationError: false,
            studentEmailAddressVerificationErrorMessage: "",
            loadingHosting: false,
            acceptTermsConditionsAndPrivacyPolicy: this.props.termsAgreed,
            mobileSideBarCollapsed: true,
            selectedOption: 'products',
            productsMenuSelected: true
        }

        this.handleClose = this.handleClose.bind(this);
        this.showModal = this.showModal.bind(this);
        this.openManageAllStripeInformationSession = this.openManageAllStripeInformationSession.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        let requestOptions = {};
        this.setState({loadingHosting : true});
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
            };
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/get-hosting-services', requestOptions);
        const data = await response.json();
        this.setState({hosting : data});
        this.setState({loadingHosting : false});
    }

    async sendStudentEmailVerification() {
        this.setState({verifyStudentEmailLoading : true});
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify({credentials : {'credential' : Cookies.get('jwtToken')}, 'student_email': this.state.studentEmailAddress})
            };
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify({credentials : {'credential' : sessionStorage.getItem('jwtToken')}, 'student_email': this.state.studentEmailAddress})
            };
        }

        const response = await fetch(EMAIL_DOMAIN + '/send-verification-email', requestOptions);
        this.setState({verifyStudentEmailLoading: false});
        const data = await response.json();
        if(data.Success){
            this.setState({verifyStudentEmailModal: false});
            window.location.href = data.url;
        }else if(data.Error){
            this.setState({studentEmailAddressVerificationError: true});
            this.setState({studentEmailAddressVerificationErrorMessage : data.Error});
        }
    }

    LogoutUser() {
        Cookies.set('jwtToken', '', { expires: 7 });
        sessionStorage.setItem('jwtToken', '');
        window.location.reload(false);
    }

    handleClose() {
        this.setState({verifyStudentEmailModal: false});
        this.setState({studentEmailAddressVerificationErrorMessage : ""});
        this.setState({studentEmailAddressVerificationError : false});
        this.setState({studentEmailAddress : ""});
    }

    showModal() {
        this.setState({verifyStudentEmailModal: true});
    }

    /*
        OPENING A MANAGE ALL SESSION WITH STRIPE
    */
    async openManageAllStripeInformationSession(){
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({'jwtToken' : Cookies.get('jwtToken')})
            };
            
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({'jwtToken' : Cookies.get('jwtToken')})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + '/create-manage-session', requestOptions);
        const data = await response.json();
        if(response.ok){
            window.location.href = data.url;
        }
        else{
            window.location.reload();
        }

    }

    goToManagePage(url){
        window.location.href = url;
    }

    handleChange() {
        this.setState({ productsMenuSelected : !this.state.productsMenuSelected });
    };

    render() {
        return (
            <div>
                <Container className="container-body">
                    <div>
                        <h1>Account</h1>
                        <div className="menu-items">
                            <div className={this.state.productsMenuSelected ? "menu-item-selected" : "menu-item"} onClick={this.handleChange}>
                                <h1 className="center">Products 
                                </h1>
                            </div>

                            <div className={this.state.productsMenuSelected ? "menu-item" : "menu-item-selected"} onClick={this.handleChange}>
                                <h1 className="center">User Info 
                                </h1>
                            </div>
                        </div>
                        <br></br>
                        {this.state.productsMenuSelected ?
                            <div>
                                <Button className="buy-products-button" onClick={this.openManageAllStripeInformationSession}>Manage Subscriptions <FaGears /></Button>
                                <Button className="buy-products-button" href='/pricing'>Shop Now <IoCartOutline /></Button>
                                <br></br>
                                <br></br>
                                {this.state.loadingHosting ? <Spinner className="center"/> :
                                <div className="hostingProducts">
                                    {this.state.hosting.subscriptions?.purchases.length === 0 && !this.state.loadingHosting && <><h5 className="no-hosting">No Products Purchased</h5><h5 className="no-hosting">Click <b>Shop Now</b></h5></>}
                                    {this.state.hosting.subscriptions?.purchases.length !== 0 && !this.state.loadingHosting &&
                                        this.state.hosting.subscriptions?.purchases.map(data => ( 
                                            <div className="hostingProductDiv" key={data.stripe_subscription_id}>
                                                <Card style={{ width: '24rem' }} onClick={() => this.goToManagePage(data.management_url)} className="purchaseCard">
                                                    <Card.Header>
                                                    <img style={{ width: 30, height: 30 }} src={icon} alt="Muffin Man Logo" /><b> {data.product_name}</b>
                                                    </Card.Header>
                                                    <Card.Body className="height-100">
                                                        {/* Is only an academy plan*/}
                                                        {data.productType.isAcademyPlan_code && data.productType.is_hosting_plan === false && 
                                                        <div>
                                                            <p><b>Academy Access: </b><span className="success">Active</span></p>
                                                            <p className="center">* CLICK TO MANAGE *</p>
                                                        </div>
                                                        }
                                                        {/* Is an academy plan and a hosting plan*/}
                                                        {data.productType.isAcademyPlan_code && data.productType.is_hosting_plan && 
                                                            <div>
                                                                <p><b>Academy Access: </b><span className="success">Active</span></p>
                                                                {data.domains.length !== 0 ? <div>
                                                                    <p><b>Hosting Plan: </b><span className="success">Active</span></p> 
                                                                    <p><b>Domain: </b> {data.domains[0].cPanelInfo.Domain}</p>
                                                                    <p><b>Storage Limit: </b> {data.domains[0].cPanelInfo.Limit}</p>
                                                                    <p><b>Database Limit: </b> {data.domains[0].cPanelInfo.SQL_limit}</p>
                                                                    <p><b>Date Created: </b> {data.domains[0].cPanelInfo.Date}</p>
                                                                </div>
                                                                : 
                                                                <div>
                                                                    <p><b>Hosting Plan: </b><span className="error">Not in use</span></p>
                                                                    <p><b>Domain: </b>N/A</p>
                                                                </div>    
                                                                }
                                                                <p className="center">* CLICK TO MANAGE *</p>
                                                            </div>
                                                        }
                                                        {/* Is only a hosting plan*/}
                                                        {data.productType.isAcademyPlan_code === false && data.productType.is_hosting_plan && 
                                                            <div>
                                                                {data.domains.length !== 0 ? <div>
                                                                    <p><b>Hosting Plan: </b><span className="success">Active</span></p> 
                                                                    <p><b>Domain: </b> {data.domains[0].cPanelInfo.Domain}</p>
                                                                    <p><b>Storage Limit: </b> {data.domains[0].cPanelInfo.Limit}</p>
                                                                    <p><b>Database Limit: </b> {data.domains[0].cPanelInfo.SQL_limit}</p>
                                                                    <p><b>Date Created: </b> {data.domains[0].cPanelInfo.Date}</p>
                                                                </div>
                                                                : 
                                                                <div>
                                                                    <p><b>Hosting Plan: </b><span className="error">Not in use</span></p>
                                                                    <p><b>Domain: </b>N/A</p>
                                                                </div>    
                                                                }
                                                                <p className="center">* CLICK TO MANAGE *</p>
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))}
                                </div>}
                            </div>
                        :
                            <div>
                                <Image src={this.state.picture} roundedCircle className="profile-image" />
                                <br></br>
                                <br></br>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3">Email address</Form.Label>
                                        <Form.Control type="email" value={this.state.email} disabled readOnly className="user-info-control"/>
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3">Name</Form.Label>
                                        <Form.Control type="text" value={this.state.name} disabled readOnly className="user-info-control"/>
                                        <Form.Text className="text-muted">
                                            We'll never share your name with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3">Verified Student</Form.Label>
                                        <Form.Control type="text" value={this.state.isStudent ? "Verified" : "Not Verified"} disabled readOnly className="user-info-control"/>
                                        <Form.Text className="text-muted">
                                            You get special pricing if you're a verified student by selecting "Verify" and entering your student email address that ends in .edu
                                        </Form.Text>
                                    </Form.Group>
                                    {this.state.isStudent === false && 
                                    <><Button onClick={this.showModal} className="user-info-button">
                                            Verify Student Email
                                    </Button>
                                    <br></br>
                                    <br></br>
                                    </>}
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-3">Terms & Conditions</Form.Label>
                                        <Form.Control type="text" value={this.state.acceptTermsConditionsAndPrivacyPolicy ? "Accepted" : "Not Accepted"} disabled readOnly className="user-info-control"/>
                                        <Form.Text className="text-muted">
                                            You will not be able to purcahse anything without agreeing to our terms and conditions.
                                        </Form.Text>
                                    </Form.Group>
                                    {this.state.acceptTermsConditionsAndPrivacyPolicy === false && 
                                    <><Button href='/terms-of-service-and-privacy-policy' className="user-info-button">
                                        Accept Terms & Conditions
                                    </Button>
                                    <br></br>
                                    </>}
                                    <hr></hr>
                                    <br></br>
                                    <Button variant="danger" onClick={this.LogoutUser}>
                                        Logout
                                    </Button>
                                </Form>
                                <br></br>
                            </div>
                        }
                    </div>
                </Container>
                <Footer />

                <Modal show={this.state.verifyStudentEmailModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Verify School Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(event) => event.preventDefault()}>
                            <Form.Group className="mb-3">
                                <Form.Label>Enter Your School Email Address That Ends in .edu</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.edu"
                                    autoFocus
                                    value={this.state.studentEmailAddress}
                                    onChange={e => this.setState({ studentEmailAddress: e.target.value })}
                                />
                            </Form.Group>
                        </Form>
                        {this.state.studentEmailAddressVerificationError && <p className="error">{this.state.studentEmailAddressVerificationErrorMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                        <Button variant="primary" disabled={this.state.verifyStudentEmailLoading} onClick={() => this.sendStudentEmailVerification()}>
                        {this.state.verifyStudentEmailLoading ? 'Loading…' : 'Send Email'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default AccountInfo;