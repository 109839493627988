import React, { Component } from 'react';
import { Form, Button, Spinner, Card } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { HOSTING_DOMAIN } from '../Variables/APIEndPoints/endpoints';

class CreateCPanelAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "",
            makecPanelAccountError: false,
            makecPanelAccountErrorText: "",
            makingCpanelAccountLoading: false,
            new_manage_url: "",
            username: "",
            password: "",
            stripe_subscription_id: this.props.stripeSubscription,
            successfulSubscription: false,
            txt_record : this.props.txt_record
        }
        this.create_cpanel_account = this.create_cpanel_account.bind(this);
    }

    async create_cpanel_account(event){
        event.preventDefault();
        this.setState({makingCpanelAccountLoading : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({domainName : this.state.domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({domainName : this.state.domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/create-cpanel-account', requestOptions);
        const data = await response.json();
        if(data.Error){
            this.setState({makecPanelAccountError : true});
            this.setState({makecPanelAccountErrorText : data.reason});
            this.setState({makingCpanelAccountLoading : false});
        }
        else{
            this.setState({makingCpanelAccountLoading : false});
            this.setState({makecPanelAccountError : false});
            this.setState({successfulSubscription : true});
            this.setState({username: data.username});
            this.setState({password : data.password});
            this.setState({new_manage_url : data.new_manage_url});
        }
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.create_cpanel_account}>
                    <Form.Group className="mb-3">
                        <Form.Label>TXT Domain Verification Text</Form.Label>
                        <Form.Control type="text" value={this.state.txt_record} disabled={true}></Form.Control>
                        <Form.Text className="text-muted">
                            This is the value you need to add as a TXT record in your DNS.
                        </Form.Text>
                        <br></br>
                        <Form.Label>Domain</Form.Label>
                        <Form.Control type="text" placeholder="example.com" value={this.state.domain} onChange={e => this.setState({domain : e.target.value})}/>
                        <Form.Text className="text-muted">
                            This is the domain you own and want to be hosted on our platform.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="create-button" disabled={this.state.makingCpanelAccountLoading}>
                        Submit {this.state.makingCpanelAccountLoading && <><Spinner size='sm'/></>}
                    </Button>
                    {this.state.makingCpanelAccountLoading && <p>This can take a while. Don't exit or refresh the page.</p>}
                    {this.state.makecPanelAccountError && <p className="error">Error: {this.state.makecPanelAccountErrorText}</p>}
                    {this.state.successfulSubscription &&
                        <>
                            <br></br>
                            <br></br>
                            <Card>
                                <Card.Header className="headerBoxes"><b>Account Credentials</b></Card.Header>
                                <Card.Body>
                                    <p><b>Username:</b> {this.state.username}</p>
                                    <p><b>Password:</b> {this.state.password}</p>
                                    <p className="error">Write these down. You will not see your <b>password</b> again.
                                    <br></br>
                                    If you forget your password go to cPanel and click "Forgot my password".
                                    </p>
                                    <Button href={this.state.new_manage_url}> To Manager Console <BsFillArrowRightCircleFill/></Button>
                                </Card.Body>
                            </Card>
                        </>
                    }
                </Form>
            </div>
        )
    }
}

export default CreateCPanelAccountForm;