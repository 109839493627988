import React, { Component } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { HOSTING_DOMAIN } from '../Variables/APIEndPoints/endpoints';


class ChangeFreeDomainNameForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe_subscription_id: this.props.stripe_subscription_id,
            new_domain: "",
            changeDomainLoading: false,
            changeDomainError: false,
            changeDomainErrorText: "",
            success: false,
            new_manage_url: ""
        }
        this.changeDomainName = this.changeDomainName.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async changeDomainName(event){
        event.preventDefault();
        this.setState({changeDomainLoading : true});
        let requestOptions = {};
        // If the cookie consent value is true.
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({new_domain_name : this.state.new_domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({new_domain_name : this.state.new_domain, stripeSubscription: this.state.stripe_subscription_id})
            };
        }
        const response = await fetch(HOSTING_DOMAIN + '/update-free-domainname', requestOptions);
        const data = await response.json();
        if(data.Error){
            this.setState({changeDomainError : true});
            this.setState({changeDomainErrorText : data.reason});
            this.setState({changeDomainLoading : false});
        }
        else{
            this.setState({changeDomainLoading : false});
            this.setState({success: true});
            this.setState({new_manage_url : data.new_manage_url});
        }
    }

    handleClose(){
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.changeDomainName}>
                    <Form.Group className="mb-3">
                        <Form.Label>New Domain: {this.state.new_domain}.domains.muffinmanhosting.com</Form.Label>
                        <Form.Control type="text" placeholder="example.com" value={this.state.new_domain} onChange={e => this.setState({new_domain : e.target.value})}/>
                        <Form.Text className="text-muted">
                            This will replace the domain you are currently using.
                        </Form.Text>
                        {this.state.changeDomainError && <p className="error">Error: {this.state.changeDomainErrorText}</p>}
                    </Form.Group>
                    <Button variant="success" type="submit" disabled={this.state.changeDomainLoading}>
                        Submit {this.state.changeDomainLoading && <Spinner size='sm'/>}
                    </Button>
                    {this.state.changeDomainLoading && <p>This can take a while. Don't exit or refresh the page.</p>}
                </Form>

                <Modal show={this.state.success} onHide={this.handleClose}>
                    <Modal.Header className="success"><b>Success</b></Modal.Header>
                        <Modal.Body>Your domain has been changed</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ChangeFreeDomainNameForm;