import React, { Component } from 'react';
import { Card, Button, Modal, Spinner } from 'react-bootstrap';
import { IoCheckmarkCircle } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { STRIPE_DOMAIN } from '../Variables/APIEndPoints/endpoints';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import './PlanChangeCard.css';

class PlanChangeCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product : props.product,
            subscriptionID: props.subscriptionID,
            showChangeProductModal: false,
            loadingChange : false,
            showErrorModal: false,
            errorMessage: "",
            showSuccessModal: false
        }
       this.openChangeProductModal = this.openChangeProductModal.bind(this);
       this.closeChangeproductModal = this.closeChangeproductModal.bind(this);
       this.openErrorModal = this.openErrorModal.bind(this);
       this.closeErrorModal = this.closeErrorModal.bind(this);
       this.openSuccessModal = this.openSuccessModal.bind(this);
       this.closeSuccessModal = this.closeSuccessModal.bind(this);
       this.changeProduct = this.changeProduct.bind(this);
    }

    openChangeProductModal() {
        this.setState({showChangeProductModal : true});
    }

    closeChangeproductModal() {
        this.setState({showChangeProductModal : false});
    }

    openErrorModal() {
        this.setState({showErrorModal : true});
    }

    closeErrorModal() {
        this.setState({showErrorModal : false});
    }

    openSuccessModal() {
        this.setState({showSuccessModal : true});
    }

    closeSuccessModal() {
        this.setState({showSuccessModal : false});
        window.location.href="/account";
    }

    async changeProduct() {
        this.setState({loadingChange : true});
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== "" && Cookies.get('jwtToken') !== undefined){
            // Preparing the request options.
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({price_id : this.state.product.current_stripe_price_code, stripeSubscription : this.state.subscriptionID})
            };
        }
        // If the cookie consent value is false or the user hasn't selected a value;
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({price_id : this.state.product.current_stripe_price_code, stripeSubscription : this.state.subscriptionID})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + '/change-product', requestOptions);
        const data = await response.json();

        if(response.ok){
            this.setState({showChangeProductModal : false});
            this.setState({showSuccessModal : true});
        }
        else if(response.status === 401){
            window.location.href = "/account";
        }
        else{
            this.setState({showChangeProductModal : false});
            this.setState({errorMessage : data.data});
            this.setState({showErrorModal : true});
        }
    }
    
    render() {
        return (
            <div>
                <Card className={this.state.product.best_value ? "product-body best-value" : "product-body plan-card-border"}>
                    {this.state.product.requiresStudentEmail && <Card.Header className={this.state.product.best_value ? "student-head plan-card-best-value-header" : "student-head plan-card-header"}>
                        <h5><b>{this.state.product.product_name}</b></h5>
                    </Card.Header>}
                    {this.state.product.requiresStudentEmail === false && <Card.Header className={this.state.product.best_value ? "non-student-head plan-card-best-value-header" : "non-student-head plan-card-header"}>
                        <h5><b>{this.state.product.product_name}</b></h5>
                    </Card.Header>}
                    <Card.Body>
                        <div className="float-left">
                            <div>
                                <h3 className="block">${this.state.product.price}</h3><p className="block">/mo</p>
                            </div>
                            <Button onClick={this.openChangeProductModal}>Change Now</Button>
                        </div>
                        <div className="clearDiv"></div>
                        <br></br>
                        <table>
                            <tr>
                                <td className="no-border"><b>Student Email Verification</b></td>
                                {this.state.product.requiresStudentEmail && 
                                    <td className="error rightTable"><b>Required</b></td>
                                }
                                {this.state.product.requiresStudentEmail === false &&
                                    <td className="success rightTable"><b>Not Required</b></td>
                                }
                            </tr>
                            {this.state.product.is_hosting_plan &&
                            <>
                            <tr>
                                <td className="no-border"><b>Free SSL / TLS</b></td>
                                {this.state.product.free_ssl_tls && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.free_ssl_tls === false && 
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="no-border"><b>SQL Databases</b></td>
                                <td className="rightTable">{this.state.product.number_sql_database}</td>
                            </tr>
                            <tr>
                                <td className="no-border"><b>subdomains</b></td>
                                <td className="rightTable">{this.state.product.number_subdomains}</td>
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>NodeJS API's</b></td>
                                {this.state.product.node_js_apis &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.node_js_apis === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>Python API's</b></td>
                                {this.state.product.python_apis && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.python_apis === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>1 Click WordPress Install</b></td>
                                {this.state.product.wordpress && 
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.wordpress === false &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            <tr>
                                <td className="table-side no-border"><b>Storage</b></td>
                                <td className="rightTable">{this.state.product.gb_storage}GB</td>
                            </tr>
                            <tr className="bottomTable">
                                <td className="table-side no-border"><b>Free Domain Name</b></td>
                                {this.state.product.free_domain_name &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {this.state.product.free_domain_name === false && 
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }
                            </tr>
                            </>}
                            <tr className="bottomTable">
                                <td className="table-side no-border"><b>Academy Access</b></td>
                                {this.state.product.isAcademyPlan_code === true &&
                                    <td className="rightTable"><IoCheckmarkCircle className="price"/></td>
                                }
                                {(this.state.product.isAcademyPlan_code === undefined || this.state.product.isAcademyPlan_code === false) &&
                                    <td className="rightTable"><TiDeleteOutline className="error"/></td>
                                }   
                            </tr>
                        </table>
                    </Card.Body>
                </Card>

                <Modal show={this.state.showChangeProductModal} onHide={this.closeChangeproductModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Clicking the <b>Change</b> button below will make your monthly payment <b>${this.state.product.price}</b>. {this.state.product.is_hosting_plan === false && <span> Also this will <b>DELETE</b> your cPanel if you have one. This is an Academy only subscription. <b>You will not get your cPanel back.</b> </span>}</Modal.Body>
                    <Modal.Footer>
                        {this.state.loadingChange ? 
                            <Button className="muffin-button" disabled="true">
                                Change <Spinner/>
                            </Button>
                            :
                            <Button className="muffin-button" onClick={this.changeProduct}>
                            Change
                            </Button>
                        }
                        {this.state.loadingChange ? <div><p>This can take a minute.</p></div> : <div></div>}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showErrorModal} onHide={this.closeErrorModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="error">There Was An Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.errorMessage}</Modal.Body>
                </Modal>
                
                <Modal show={this.state.showSuccessModal} onHide={this.closeSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="success">Change Successful</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your product subscription has been updated!</Modal.Body>
                </Modal>
                
            </div>



        )
    }
}

export default PlanChangeCard;