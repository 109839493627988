import React, { Component } from 'react';
import { Button} from 'react-bootstrap';
import Cookies from 'js-cookie';
import {getCookieConsentValue} from "react-cookie-consent";
import { STRIPE_DOMAIN } from '../Variables/APIEndPoints/endpoints';

class ManageSubscriptionInformationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripe_subscription_id : this.props.stripeSubscription,
            subscriptionObject: undefined,
            error: false,
            errorText: ""
        }
        this.openCancelSubscriptionSession = this.openCancelSubscriptionSession.bind(this);
        this.renewSubscription = this.renewSubscription.bind(this);
    }


    async componentDidMount(){
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        let stripe_subscription_id = params.get("stripeSubscription");
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({'stripeSubscription' : stripe_subscription_id})
            };
            
            const response = await fetch(STRIPE_DOMAIN + '/get-subscription-information', requestOptions);
            const data = await response.json();
            this.setState({subscriptionObject: data.stripe_subscription_object});
        }
        else{
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({'stripeSubscription' : stripe_subscription_id})
            };
    
            const response = await fetch(STRIPE_DOMAIN + '/get-subscription-information', requestOptions);
            const data = await response.json();
            this.setState({subscriptionObject: data.stripe_subscription_object});
        }
    }

    /*
    * OPENING A CANCEL SUBSCRIPTION SESSION WITH STRIPE
    */
    async openCancelSubscriptionSession(stripe_subscription_id){
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({'stripe_subscription_id' : stripe_subscription_id})
            };
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({'stripe_subscription_id' : stripe_subscription_id})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + '/create-cancel-subscription-session', requestOptions);
        const data = await response.json();
        if(response.ok){
            window.location.href = data.url
        }
        else{
            window.location.reload();
        }
        
    }

    async renewSubscription(stripe_subscription_id){
        let requestOptions = {};
        if(getCookieConsentValue() === "true" && Cookies.get('jwtToken') !== undefined && Cookies.get('jwtToken') !== ""){
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + Cookies.get('jwtToken')},
                body: JSON.stringify({'stripeSubscription' : stripe_subscription_id})
            };
        }
        else{
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type' : 'application/json', 'Authorization' : 'Bearer ' + sessionStorage.getItem('jwtToken')},
                body: JSON.stringify({'stripeSubscription' : stripe_subscription_id})
            };
        }
        const response = await fetch(STRIPE_DOMAIN + '/renew-subscription', requestOptions);
        const data = await response.json();
        if(data.Success){
            window.location.reload();
        }
        else{
            this.setState({error: true});
            this.setState({errorText : data.reason});
        }
    }

    render() {
        return (
            <div>
                {this.state.subscriptionObject !== undefined && this.state.subscriptionObject.cancel_at_period_end === false && <Button className="mb-2" type="submit" variant="danger" onClick={() => this.openCancelSubscriptionSession(this.state.stripe_subscription_id)}>Cancel Subscription</Button>}
                {this.state.subscriptionObject !== undefined && this.state.subscriptionObject.cancel_at_period_end === true && <Button className="mb-2" type="submit" variant="success" onClick={() => this.renewSubscription(this.state.stripe_subscription_id)}>Renew Subscription</Button>}
                {this.state.error && <p className="error">Error : {this.state.errorText}</p>}
            </div>
        )
    }
}

export default ManageSubscriptionInformationForm;